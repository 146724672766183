import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Wrapper from '../components/wrapper';

const AuthorPage = () => (
  <Layout>
    <SEO title="O'Reilly Author and Medium Writer" />
    <Wrapper>
      <p>
        I'm the co-author of{' '}
        <a href="http://shop.oreilly.com/product/0636920032502.do">
          "Lightweight Django"
        </a>{' '}
        for O'Reilly Media along with{' '}
        <a href="http://juliaelman.com/">Julia Elman</a>. I also recorded the{' '}
        <a href="http://shop.oreilly.com/product/0636920040903.do">
          Intermediate Django
        </a>{' '}
        video series on building modern, scalable, and maintainable web
        applications with Django, also for O'Reilly.
      </p>
      <iframe
        src="http://www.oreilly.com/authors/widgets/660.html"
        height="350px"
        width="200px"
        scrolling="no"
        frameborder="0"
      ></iframe>
      <iframe
        src="http://www.oreilly.com/authors/widgets/760.html"
        height="350px"
        width="200px"
        scrolling="no"
        frameborder="0"
      ></iframe>
      <p>
        More recently I've been writing about Python development and my other
        hobbies on <a href="https://medium.com/@markdlavin">Medium</a>.
      </p>
      <p>
        I enjoy speaking at conferences about Python, general web development
        practices, and technical leadership and hiring.
      </p>
      <h3>Speaking</h3>
      <article class="speaking">
        <ul>
          <li>OMGWFTAMQP - All Things Open - Oct 2016</li>
          <li>
            You Belong with Me: Scraping Taylor Swift Lyrics with Python and
            Celery (with Rebecca Conley) - PyData Carolinas - Sept 2016
          </li>
          <li>Developer Training (Keynote) - Python Nordeste - June 2016</li>
          <li>
            Just be Nice: Rethinking Tech Hiring - All Things Open - Oct 2015
          </li>
          <li>Intro to Client Side Testsing - DjangoCon - Sept 2015</li>
          <li>
            Building SMS Applications with Django (with David Ray and Caleb
            Smith) - PyCon Sponsor Tutorial - April 2015
          </li>
          <li>
            Testing Client-Side Applications with Django - O'Reilly Webcast -
            Jan 2015
          </li>
          <li>
            Creating Enriching Web Applications with Django and Backbone.js -
            O'Reilly Webcast - Nov 2014
          </li>
          <li>Anatomy of a Django Project - DjangoCon - Sept 2014</li>
          <li>REST: It's Not Just for Servers - DjangoCon - Sept 2014</li>
          <li>REST: It's Not Just for Servers - OSCon - July 2014</li>
          <li>
            Developers and Designers: Collaborating on your Open Source Project
            (with <a href="http://juliaelman.com/">Julia Elman</a>) - OSCon -
            July 2013
          </li>
          <li>
            Developers and Designers: Collaborating on your Python Project (with{' '}
            <a href="http://juliaelman.com/">Julia Elman</a>) - PyCon - Mar 2013
          </li>
          <li>
            Maintaining Your Sanity While Maintaining Your Open Source App -
            PyCarolinas - Oct 2012
          </li>
          <li>
            Maintaining Your Sanity While Maintaining Your Open Source App -
            DjangoCon - Sept 2012
          </li>
        </ul>
      </article>
      <p>
        You can also find my various posts from my previous job on the{' '}
        <a href="http://www.caktusgroup.com/blog/author/mark-lavin/">
          Caktus blog
        </a>
        .
      </p>
    </Wrapper>
  </Layout>
);

export default AuthorPage;
